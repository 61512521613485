import { defer } from 'react-router-dom'

import { gql } from 'graphql-generated'
import type { OverviewOfPollsQuery } from 'graphql-generated/graphql'
import { gqlRequest } from 'api'

const OVERVIEW_OF_POLLS_QUERY = gql(`
  query OverviewOfPolls {
    polls_entrytaggroup(order_by: { order: asc }) {
      id
      name
    }
    polls_poll(order_by: {date_modified: desc}) {
      id
      type
      name
      description
      is_external
      entries_aggregate {
        aggregate {
          count
        }
      }
      required_entry_tag_groups {
        entrytaggroup_id
      }
      optional_entry_tag_groups {
        entrytaggroup_id
      }
      date_modified
    }
  }
`)

function makePollsFromData(polls: OverviewOfPollsQuery['polls_poll']) {
  return polls.map(
    ({
      __typename,
      description,
      is_external,
      entries_aggregate,
      required_entry_tag_groups,
      optional_entry_tag_groups,
      date_modified,
      ...rest
    }) => ({
      ...rest,
      description: description || undefined,
      isExternal: is_external,
      entries: entries_aggregate.aggregate?.count || 0,
      requiredEntryTagGroupIds: required_entry_tag_groups.map(
        ({ entrytaggroup_id }) => entrytaggroup_id
      ),
      optionalEntryTagGroupIds: optional_entry_tag_groups.map(
        ({ entrytaggroup_id }) => entrytaggroup_id
      ),
      dateModified: new Date(date_modified),
    })
  )
}

function makeEntryTagGroupsFromData(data: OverviewOfPollsQuery) {
  const polls = makePollsFromData(data.polls_poll)

  const entryTagGroups = data.polls_entrytaggroup
    .map(({ id, name }) => ({
      id,
      name,
      slug: name.toLowerCase().replace(/\s/g, '-'),
      polls: polls
        .filter(
          ({
            isExternal,
            requiredEntryTagGroupIds,
            optionalEntryTagGroupIds,
          }) =>
            isExternal === false &&
            (requiredEntryTagGroupIds.includes(id) ||
              optionalEntryTagGroupIds.includes(id))
        )
        .filter(({ name: pollName }) => {
          const tagNameRegExp = new RegExp(`^${name}`, 'i')

          return tagNameRegExp.test(pollName)
        }),
    }))
    .filter(({ polls }) => polls.length > 0)

  const external = {
    id: 0,
    name: 'External',
    slug: 'external',
    polls: polls.filter(({ isExternal }) => isExternal),
  }

  return [...entryTagGroups, external]
}

export type EntryTagGroups = ReturnType<typeof makeEntryTagGroupsFromData>

async function getEntryTagGroups() {
  const data = await gqlRequest(OVERVIEW_OF_POLLS_QUERY)

  return makeEntryTagGroupsFromData(data)
}

export default function overviewOfPollsLoader() {
  return defer({ entryTagGroups: getEntryTagGroups() })
}
